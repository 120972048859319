<template>

<div class="main-contents">
            <!-- 열람기업 리스트 -->
            <div class="reading_container">
                <div class="reading_info_contents">
                    <div class="title">
                        내 이력서 열람기업<br/>
                        <span>총 <span class="data">{{pageInfo.totalRecordCount}}</span>건의 열람이 있습니다!</span>
                    </div>
                    <div class="Board">
                        <!-- 열람기업 표 -->
                        <table class="Board_type1">
                            <colgroup>
                                <col width="50">
                                <col width="70">
                                <col width="268">
                                <col width="268">
                                <col width="268">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th><input type="checkbox" :checked="allChecked" @click="checkedAll($event.target.checked)"></th>
                                    <th>NO</th>
                                    <th>열람일</th>
                                    <th>기업명</th>
                                    <th>담당자</th>
                                </tr>
                            </thead>
                            <tbody>

								<tr v-for="(view, index) in viewHistList" :key="view.resumeViewHistorySeq">
									<td>
										<input type="checkbox" 
											v-model="input.checkedSeqList" 
											:value="view.resumeViewHistorySeq"
										>
									</td>
									<!-- <td>{{ (pageInfo.pageIndex - 1) * pageInfo.pageSize + (index + 1)}}</td> -->
									<td>{{ (pageInfo.pageIndex - 1) * pageInfo.pageUnit + (index + 1)}}</td>
									<td>{{view.viewDate}}</td>
                                    <td>{{view.corpNm}}</td>
                                    <td>{{view.corpMberNm}}</td>
								</tr>

								<tr v-if="pageInfo.totalRecordCount == 0">
									<td colspan="5" class="none">이력서를 열람한 기업이 없습니다!</td>
								</tr>								

                            </tbody>
                        </table>
                        <!-- 삭제버튼 -->
                        <div class="btn_delete" @click="deleteHist()">
                            삭제
                        </div>

						<!--페이징 컴포넌트 -->
						<PagingComp :pageInfo="pageInfo" @page-click="goPage"/>
						<!--// 페이징 컴포넌트 -->

                    </div>
                </div>
            </div>
        </div>

</template>

<script>

import PagingComp from '@/components/PagingComp.vue';

export default {

	components : {
		PagingComp
	},

	data() {
		return {
			input: {
				pageIndex : '1',
				checkedSeqList : []
			}, // 검색조건		
			viewHistList : [],
			pageInfo : {},

			allChecked : false,
			
		};
	},
	bforeCreate() {
		// console.log('beforeCreate');
	},
	created() {
		// console.log('created');
	},
	beforeMount() {
		// console.log('beforeMount');
	},
	mounted() {
		// console.log('mounted');
		this.getList();
	},
	beforeUpdate() {
		// console.log('beforeUpdate');
	},
	updated() {
		// console.log('update');

		// console.log('this.viewHistList.length : ' + this.viewHistList.length);
		// console.log('this.input.checkedSeqList.length : ' + this.input.checkedSeqList.length);

		if(this.viewHistList.length == this.input.checkedSeqList.length && this.viewHistList.length > 0) {
			this.allChecked = true;
		} else {
			this.allChecked = false;
		}
	},
	beforeDestroy() {
		// console.log('beforeDestroy');
	},
	destroyed() {
		// console.log('destroyed');
	},

	methods: {
		getList() {
			this.$.httpPost('/api/mem/prf/getResViewHistList', this.input)
				.then(res => {
					// console.log(res.data);
					this.viewHistList = res.data.list;
					this.pageInfo = res.data.pageInfo;
					this.input.checkedSeqList = [];

					window.scroll(0, 0);
				})
				.catch(err => {
					// console.log('============================='); 
					// console.log(err);
					// console.log(err.response);
					// console.log('============================='); 

					alert(err.response.data.error_description);
				});
		},

		deleteHist() {
			if(this.input.checkedSeqList.length <= 0) {
				alert('삭제할 데이터를 선택하세요.');
				return;
			}

			if(!confirm('삭제 하시겠습니까?')) {
				return;
			}

			this.$.httpPost('/api/mem/prf/deleteResViewHistList', this.input)
				.then(() => {
					// console.log(res.data);
					
					this.input.pageIndex = '1';
					this.getList();
				})
				.catch(err => {
					// console.log('============================='); 
					// console.log(err);
					// console.log(err.response);
					// console.log('============================='); 

					alert(err.response.data.error_description);
				});			
		}, 

		goPage(page) {
			// console.log(page);
			this.input.pageIndex = page;
			this.getList();
		},

		checkedAll(checked) {

			this.input.checkedSeqList = [];
			this.allChecked = checked;

			if(this.allChecked) {				
				for(var i in this.viewHistList)	 {
					this.input.checkedSeqList.push(this.viewHistList[i].resumeViewHistorySeq);
				}
			}
		},
	},
};
</script>
